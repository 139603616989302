<template>
  <div>
    <section class="help-musucuans-hero">
      <b-jumbotron class="mb-0">
        <b-container class="h-100">
          <b-row align-v="center" class="h-100">
            <b-col cols="12" v-if="portalConfig.dynamicHeroArray.length  === 0">
              <h2 class="banner-text text-center">{{ portalConfig.text.mainTagLine }}</h2>
              <h4 class="banner-subtext text-center">{{ portalConfig.text.bottomTagLine }}</h4>
              <SearchHero />
            </b-col>
            <b-col cols="12" v-if="portalConfig.dynamicHeroArray.length !== 0" class="mt-md-5">
              <h1 class="sr-hero-text" v-for="header in portalConfig.dynamicHeroArray" :key="header.id">{{ header.value }}</h1>
            </b-col>
          </b-row>
        </b-container>
      </b-jumbotron>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SearchHero from '@/components/SearchHero'
export default {
  name: 'HeroWithSearch',
  components: {
    SearchHero
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  }
}
</script>
