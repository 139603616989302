<template>
  <div>
    <b-button class="support-button d-none d-md-block" variant="primary" @click="$bvModal.show(`${modalTitle}`)">{{ buttonTitle }}
    </b-button>
    <b-button pill variant="primary" class="support-button d-block d-md-none"  @click="$bvModal.show(`${modalTitle}`)">
      <font-awesome-icon v-if="type === 1" :icon="['far', 'headset']" />
      <font-awesome-icon v-if="type === 2" :icon="['fas', 'megaphone']" />
    </b-button>
    <b-modal :id="modalTitle" :title="modalTitle" scrollable size="lg">
    <div v-html="content"></div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" aria-label="Close" style="color: #ffffff;" variant="primary" @click="ok()">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeadset, faPhoneAlt } from '@fortawesome/pro-regular-svg-icons'
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons'

library.add(faHeadset, faPhoneAlt, faMegaphone)

export default {
  name: 'PhoneModal',
  props: {
    buttonTitle: String,
    modalTitle: String,
    content: String,
    type: Number
  }
}
</script>
