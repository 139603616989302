<template>
  <div>
    <div class="directory-theme" v-if="portalConfig.themeLayout.id === 1 || portalConfig.themeLayout.id === 2">
      <NavBar v-if="portalConfig.navigationType === 1" />
      <NavBarChamp v-if="portalConfig.navigationType === 2"/>
      <slot />
      <Footer />
    </div>
    <div class="directory-theme" v-if="portalConfig.themeLayout.id === 3">
      <b-container v-if="portalConfig.navigationType === 1">
        <NavBar />
      </b-container>
      <NavBarChamp v-if="portalConfig.navigationType === 2"/>
      <NavBarStirling v-if="portalConfig.navigationType === 3" />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/layout/menu/NavBar'
import NavBarStirling from '@/components/layout/menu/NavBarStirling'
import NavBarChamp from '@/components/layout/menu/NavBarChamp'
import Footer from '@/components/layout/Footer'
import { mapGetters } from 'vuex'
export default {
  name: 'DirectoryTheme',
  components: {
    NavBar,
    NavBarStirling,
    NavBarChamp,
    Footer
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  }
}
</script>
