const state = () => ({ navStatus: false })

const getters = {
  getNavStatus: state => {
    return state.navStatus
  }
}

const mutations = {
  toggleNav (state) {
    state.navStatus = !state.navStatus
  },
  resetNav (state) {
    state.navStatus = false
  }
}

export default {
  state,
  getters,
  mutations
}
