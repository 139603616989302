import Vue from 'vue'
import Vuex from 'vuex'

import navStore from './modules/navStore.js'
import elasticStore from './modules/elasticSearch.js'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  portalConfig: {}
}
// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  setPortalConfig (state, payload) {
    state.portalConfig = payload.portalConfig
  }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  async commitConfig ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setPortalConfig', payload)
      resolve()
    })
  }
}

// getters are functions.
const getters = {
  getPortalConfig (state) {
    return state.portalConfig
  },
  getContactForm (state) {
    return state.portalConfig.contactForm
  }
}

// modules can be used to reduce bloating in vuex stores
// each module can have it's own state, mutations, actions, getters and even nested modules

const modules = {
  navModule: navStore,
  elasticModule: elasticStore
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules
})
