<template>
  <div class="elastic-search mt-2 mb-5">
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="10" md="4" offset="1" offset-md="0">
          <VueTypeaheadBootstrap
            v-model="query"
            :data="suggestions"
            :disableSort="true"
            placeholder="Search Term"
            @input="searchUsers"
            @keyup.enter="search"
            @hit="selectTerm($event)"
            :showAllResults="true"
            :minMatchingChars="1"
          >
            <template slot="append">
              <b-button class="btn btn-primary" @click="search">
                <font-awesome-icon class="icon" :icon="['fas', 'search']" />
              </b-button>
            </template>
          </VueTypeaheadBootstrap>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import debounce from 'debounce'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
library.add(faSearch)

export default {
  name: 'SearchHero',
  components: {
    VueTypeaheadBootstrap
  },
  data: () => {
    return {
      query: '',
      suggestions: [],
      state: 'close'
    }
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  },
  methods: {
    ...mapActions(['getSearchResults']),
    selectTerm (event) {
      this.query = event
      this.search()
    },
    searchUsers: debounce(async function () {
      const URL = process.env.VUE_APP_APIURL + 'articles/' + this.portalConfig.portalName + '/autocomplete/elastic'
      const body = {
        term: this.query
      }
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: body,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      const res = await axios(ops)
      this.suggestions = res.data
    }, 500),
    search () {
      if (this.query.length !== 0) {
        const query = {
          portalName: this.portalConfig.portalName,
          query: this.query
        }
        this.getSearchResults(query)
        this.query = ''
        if (this.$route.path !== '/results') {
          this.$router.push('/results')
        }
      }
    }
  }
}
</script>
