<template>
  <div>
    <b-button class="report-button d-none d-md-block" v-b-modal.modal-prevent-closing>{{contactForm.buttonTitle}}</b-button>
    <b-button pill class="report-button d-block d-md-none" v-b-modal.modal-prevent-closing><font-awesome-icon :icon="['far', 'envelope']" /></b-button>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      size="lg"
      :title="contactForm.formTitle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      scrollable
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          v-if="contactForm.optionActive"
          :state="typeState"
          :label="contactForm.optionTitle"
          label-for="type-input"
          invalid-feedback="Please select a report type"
          valid-feedback
        >
          <b-form-text class="form-text">
            {{contactForm.optionText}}
          </b-form-text>
          <b-form-select
            v-if="contactForm.optionDisplayType === 1"
            id="type-input"
            v-model="formData.bugType"
            :options="options"
            :state="typeState"
            required
          ></b-form-select>
          <b-form-radio-group
            v-if="contactForm.optionDisplayType === 2"
            v-model="formData.bugType"
            :options="options"
            :state="typeState"
            required
            stacked
          >
          </b-form-radio-group>
        </b-form-group>
        <b-form-group
          v-if="contactForm.subjectActive"
          :state="subjectState"
          :label="contactForm.subjectTitle"
          label-for="subject-input"
          invalid-feedback="Subject is required"
          valid-feedback
        >
          <b-form-input
            id="subject-input"
            v-model="formData.bugSubject"
            :state="subjectState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="contactForm.messageActive"
          :label="contactForm.messageTitle"
          label-for="message-input"
        >
          <b-form-textarea
            id="textarea-auto-height"
            v-model="formData.bugMessage"
            :placeholder="contactForm.messagePlaceholder"
            min-rows="2"
          ></b-form-textarea>
        </b-form-group>
        <b-form-text class="form-text">
          {{contactForm.contactDescription}}
        </b-form-text>
        <b-row align-h="center">
          <b-col cols="12" md="4" v-if="contactForm.firstNameActive">
            <b-form-group
              :label="contactForm.firstNameTitle"
              label-for="first-input"
            >
              <b-form-input
                id="first-input"
                v-model="formData.firstName"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="contactForm.lastNameActive">
            <b-form-group
              :label="contactForm.lastNameTitle"
              label-for="last-input"
            >
              <b-form-input
                id="last-input"
                v-model="formData.lastName"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="contactForm.phoneNumberActive">
            <b-form-group
              :label="contactForm.phoneNumberTitle"
              label-for="phone-input"
            >
              <b-form-input
                id="phone-input"
                v-model="formData.telephone"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="contactForm.emailActive" align-h="center">
          <b-col cols="12" md="6">
            <b-form-group
              :label="contactForm.emailTitle"
              label-for="email-input"
              :state="emailState"
              :description="contactForm.contactDisclaimer"
              invalid-feedback="Please provide a correct email address"
            >
              <b-form-input
                type="email"
                id="email-input"
                v-model="formData.email"
                :state="emailState"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
            <template v-slot:modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" aria-label="Cancel" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" aria-label="Send" variant="primary" @click="ok()">
          Send
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import reportService from '@/services/ReportBug.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { mapGetters } from 'vuex'

library.add(faEnvelope)
export default {
  data () {
    return {
      options: [],
      typeState: null,
      subjectState: null,
      messageState: null,
      emailState: null,
      formData: {
        bugType: '',
        bugSubject: '',
        bugMessage: '',
        url: '',
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        reportType: ''
      },
      boxTwo: '',
      boxThree: ''
    }
  },
  computed: {
    ...mapGetters(
      { contactForm: 'getContactForm' }
    )
  },
  mounted () {
    this.setOptions()
  },
  methods: {
    setOptions () {
      const holdArray = []
      this.contactForm.options.forEach((element) => {
        holdArray.push(element.text)
      })
      this.options = holdArray
    },
    setUrl () {
      this.formData.url = window.location.href
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      if (this.contactForm.optionActive && valid) {
        this.typeState = valid
      }
      if (this.contactForm.messageActive && valid) {
        this.messageState = valid
      }
      if (this.contactForm.subjectActive && valid) {
        this.subjectState = valid
      }
      if (this.contactForm.emailActive && valid) {
        this.emailState = valid
      }
      return valid
    },
    resetModal () {
      this.formData = {
        bugType: '',
        bugSubject: '',
        bugMessage: '',
        url: '',
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        reportType: ''
      }
      this.typeState = null
      this.subjectState = null
      this.messageState = null
      this.emailState = null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.setUrl()
      this.formData.reportType = this.contactForm.type
      // If successful, show the successful modal
      await reportService.reportBug(this.formData)
        .then((res) => {
          if (res.status === 202) {
            this.showMsgSuccess()
          } else {
            this.showMsgError()
          }
        })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    showMsgSuccess () {
      this.boxTwo = ''
      this.$bvModal.msgBoxOk('Your report was submitted successfully!', {
        title: 'Thanks',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          this.boxTwo = value
        })
        .catch(err => {
          // eslint-disable-next-line
          console.error(err)
        })
    },
    showMsgError () {
      this.boxThree = ''
      this.$bvModal.msgBoxOk('Sorry! It looks like something`s gone wrong!', {
        title: 'Error',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          this.boxThree = value
        })
        .catch(err => {
          // eslint-disable-next-line
          console.error(err)
        })
    }
  }
}
</script>
