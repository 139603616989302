<template>
  <div>
    <b-navbar :class="{ 'navbar-white' : isHome === 'Home' }">
      <b-navbar-brand v-b-tooltip.bottomright.v-primary title="Home" v-on:click="clearPagination" :to="'/'"></b-navbar-brand>
      <b-navbar-nav class="ml-auto d-none d-lg-flex">
        <b-button class="logout-btn" v-if="$auth.isAuthenticated" variant="outline-primary" @click="logout">Logout <font-awesome-icon class="ml-1" :icon="['fal', 'sign-out-alt']" /></b-button>
      </b-navbar-nav>
      <Sidebar class="d-block d-lg-none">
        <div class="sidebar-panel-nav">
          <b-nav vertical>
            <b-nav-item v-on:click="clearPagination" :to="'/'">Home</b-nav-item>
          </b-nav>
        </div>
        <b-navbar-nav class="ml-auto d-none d-lg-flex">
          <b-button class="logout-btn" v-if="$auth.isAuthenticated" variant="outline-primary" @click="logout">Logout <font-awesome-icon class="ml-1" :icon="['fal', 'sign-out-alt']" /></b-button>
        </b-navbar-nav>
      </Sidebar>
      <BurgerBtn class="d-inline-block d-lg-none"></BurgerBtn>
    </b-navbar>
  </div>
</template>

<script>
import BurgerBtn from '@/components/layout/menu/BurgerBtn'
import Sidebar from '@/components/layout/menu/Sidebar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLink, faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

library.add(faExternalLink, faSignOutAlt, faChevronDown)

export default {
  name: 'NavBar',
  components: {
    BurgerBtn,
    Sidebar
  },
  computed: {
    isHome () {
      return this.$route.name
    },
    userStatus () {
      return this.$auth.isAuthenticated
    }
  },
  methods: {
    clearPagination () {
      localStorage.removeItem('pageL')
      localStorage.removeItem('pageV')
    },
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
