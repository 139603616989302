<template>
  <b-alert class="sticky" :show="cookieAlert" variant="light" fade>
    <p v-if="cookieAlert === true">{{ portalConfig.text.cookiePopUp }}</p>
    <b-btn variant="success" @click="userAgree">Agree</b-btn>
    <b-btn variant="danger" @click="userDecline">Disagree</b-btn>
  </b-alert>
</template>

<script>
import { setOptions, bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
export default {
  name: 'CookieBanner',
  data: () => {
    return {
      cookieAlert: null,
      userConsent: null
    }
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  },
  created () {
    this.checkUser()
  },
  methods: {
    checkUser () {
      this.userConsent = localStorage.getItem('userConsent')
      if (this.userConsent === 'true') {
        const ID = this.portalConfig.trackingID
        if (ID) {
          setOptions({
            config: { id: ID }
          })
          bootstrap().then((gtag) => {
            this.cookieAlert = false
            this.track()
            // analytics enabled
          })
        }
      } else if (this.userConsent === 'false') {
        this.cookieAlert = true
      } else {
        this.cookieAlert = true
      }
    },
    userAgree () {
      localStorage.setItem('userConsent', true)
      if (this.portalConfig !== null) {
        const ID = this.portalConfig.trackingID
        if (ID) {
          setOptions({
            config: { id: ID }
          })
          bootstrap().then((gtag) => {
            this.cookieAlert = false
            this.track()
            this.userConsent = true
            // analytics enabled
          })
        } else {
          // eslint-disable-next-line
          console.error('No ID could be set for Google Analytics')
        }
      }
      this.cookieAlert = false
    },
    userDecline () {
      this.userConsent = false
      localStorage.setItem('userConsent', false)
      this.cookieAlert = false
    },
    track () {
      this.$gtag.pageview({
        page_path: '/'
      })
    }
  }
}
</script>
