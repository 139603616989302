<template>
  <div>
    <section class="hero">
      <NavBar/>
      <MainSearch />
      <b-link href="#" v-scroll-to="'#champ-cinema'" class="scroll-down align-items-end">Explore<font-awesome-icon :icon="['fas', 'caret-down']" /></b-link>
    </section>
  </div>
</template>
<script>
import NavBar from '@/components/layout/menu/NavBar.vue'
import MainSearch from './MainSearch.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretDown)

export default {
  name: 'SectionHero',
  components: {
    MainSearch,
    NavBar
  },
  component: {
    faCaretDown
  }
}
</script>
