<script>
import axios from 'axios'

export default {
  async searchListings (portalName, searchterms, count, page) {
    const URL = process.env.VUE_APP_APIURL + 'articles/' + portalName + '/listings/search' + count + page
    var lAttributes = {
      postcode: searchterms.postcode,
      tagid: searchterms.terms,
      distance: searchterms.distance,
      supportType: searchterms.topic
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: lAttributes,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async searchVideos (portalName, searchterms, count, page) {
    const URL = process.env.VUE_APP_APIURL + 'articles/' + portalName + '/videos/search' + count + page
    var vAttributes = {
      supportType: searchterms.topic,
      ageRange: searchterms.agegroup,
      tagid: searchterms.terms,
      gender: searchterms.gender,
      Viewpoint: searchterms.viewpoint
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: vAttributes,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async searchResources (portalName, searchterms, count, page) {
    const URL = process.env.VUE_APP_APIURL + 'articles/' + portalName + '/resources/search' + count + page
    var rAttributes = {
      supportType: searchterms.topic,
      tagid: searchterms.terms,
      resourceType: searchterms.resourceType
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: rAttributes,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getRequest (portalName, endpoint) {
    const URL = process.env.VUE_APP_APIURL + 'articles/' + portalName + '/' + endpoint
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res.data
    } catch (error) {
      return error.response
    }
  },
  async advancedFilter (portalName) {
    const URL = process.env.VUE_APP_APIURL + 'articles/' + portalName + '/advancedfilter'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async searchLink (portalName) {
    const URL = process.env.VUE_APP_APIURL + 'articles/' + portalName + '/searchlink'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
