<template>
  <div id="burger" :class="{ 'active' : getNavStatus }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'BurgerBtn',
  computed: {
    ...mapGetters(['getNavStatus'])
  },
  methods: {
    ...mapMutations({ toggle: 'toggleNav' })
  }
}
</script>
