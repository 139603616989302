<template>
  <b-container class="pt-2 mb-0 p-nav" v-if="portalConfig.navigationDropDown.length !== 0">
    <b-row>
        <b-nav>
          <b-nav-item @click="goBack"> <font-awesome-icon class="mr-1" :icon="['fas', 'chevron-left']" />Back</b-nav-item>
        </b-nav>
        <b-nav class="ml-auto">
          <div v-for="(menuLink, index) in portalConfig.navigationDropDown" :key="index">
            <b-nav-item-dropdown no-caret right v-if="menuLink.linkType === 4" :href="menuLink.link">
              <template #button-content>
                  {{ menuLink.name }}
                  <font-awesome-icon :icon="['fas', 'chevron-down']" />
                </template>
              <div v-for="(menuDropLink, index) in menuLink.children" :key="index">
                <b-dropdown-item v-if="menuDropLink.linkType === 1"  @click="clearPagination + search(menuDropLink.link)">{{ menuDropLink.name }}</b-dropdown-item>
                <b-dropdown-item  no-caret v-if="menuDropLink.linkType === 2"  @click="clearPagination + search(menuDropLink.link)">{{ menuDropLink.name }}</b-dropdown-item>
              </div>
            </b-nav-item-dropdown>
          </div>
        </b-nav>
    </b-row>
  </b-container>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLink, faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { mapGetters, mapActions } from 'vuex'

library.add(faExternalLink, faSignOutAlt, faChevronDown, faChevronLeft)

const breakpoints = {
  sm: 540,
  md: 720,
  lg: 960
}

export default {
  name: 'PNav',
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    }),
    isHome () {
      return this.$route.name
    }
  },
  created () {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },
  methods: {
    ...mapActions(['getSearchResults']),
    search (query) {
      if (this.portalConfig.themeLayout.id === 2 || this.portalConfig.themeLayout.id === 3) {
        if (query.length !== 0) {
          const searchQuery = {
            portalName: this.portalConfig.portalName,
            query: query,
            count: this.count
          }
          this.getSearchResults(searchQuery)
          if (this.$route.path !== '/results') {
            this.$router.push('/results')
          }
        }
      }
    },
    goBack () {
      return this.$router.go(-1)
    },
    clearPagination () {
      localStorage.removeItem('pageL')
      localStorage.removeItem('pageV')
    },
    resizeHandler (e) {
      const clientWidth = window.innerWidth
      if (clientWidth < breakpoints.md) {
        this.count = 'count=3'
      } else {
        this.count = 'count=6'
      }
    }
  }
}
</script>
