<template>
  <div>
    <BetaWarning />
    <b-container>
    <b-navbar :class="{ 'navbar-white' : isHome === 'Home' }">
      <b-navbar-brand v-b-tooltip.bottomright.v-primary :title="portalConfig.text.logoToolTip" v-on:click="clearPagination" :to="'/'"></b-navbar-brand>
       <b-link class="nav-link-external" v-if="portalConfig.text.externalLinkURL" :href="portalConfig.text.externalLinkURL" target="_blank">{{ portalConfig.text.externalLinkText }}<font-awesome-icon class="ml-1" :icon="['fal', 'external-link']" /></b-link>
        <b-navbar-nav class="ml-auto d-none d-lg-flex">
          <div v-for="(menuLink, index) in portalConfig.menuItems" :key="index">
            <b-nav-item v-if="menuLink.linkType === 1" :to="menuLink.link" v-on:click="clearPagination">{{ menuLink.name }}</b-nav-item>
            <b-nav-item v-if="menuLink.linkType === 2" :href="menuLink.link" v-on:click="clearPagination">{{ menuLink.name }}</b-nav-item>
            <b-button class="logout-btn" v-if="menuLink.linkType === 3" variant="outline-primary" @click="logout">{{ menuLink.name }} <font-awesome-icon class="ml-1" :icon="['fal', 'sign-out-alt']" /></b-button>
            <b-nav-item-dropdown v-if="menuLink.linkType === 4" :href="menuLink.link" :text="menuLink.name">
              <div v-for="(menuDropLink, index) in menuLink.children" :key="index">
                <b-dropdown-item v-if="menuDropLink.linkType === 1" :to="menuDropLink.link" v-on:click="clearPagination">{{ menuDropLink.name }}</b-dropdown-item>
                <b-dropdown-item v-if="menuDropLink.linkType === 2" :href="menuDropLink.link" v-on:click="clearPagination">{{ menuDropLink.name }}</b-dropdown-item>
              </div>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      <Sidebar class="d-block d-lg-none">
        <div class="sidebar-panel-nav">
            <b-nav vertical>
              <b-nav-item v-on:click="clearPagination" :to="'/'">Home</b-nav-item>
              <div v-for="(menuLink, index) in portalConfig.menuItems" :key="index">
                <b-nav-item v-if="menuLink.linkType === 1" :to="menuLink.link" v-on:click="clearPagination">{{ menuLink.name }}</b-nav-item>
                <b-nav-item v-if="menuLink.linkType === 2" :href="menuLink.link" v-on:click="clearPagination">{{ menuLink.name }}</b-nav-item>
                <b-nav-item v-if="menuLink.linkType === 4" v-b-toggle="menuLink.link" class="m-1">{{ menuLink.name }} <span class="d-lg-none float-right"><font-awesome-icon :icon="['fas', 'chevron-down']" /></span></b-nav-item>
                <b-collapse v-if="menuLink.linkType === 4" :id="menuLink.link">
                <div v-for="(menuDropLink, index) in menuLink.children" :key="index">
                  <b-dropdown-item v-if="menuDropLink.linkType === 1" :to="menuDropLink.link" v-on:click="clearPagination">{{ menuDropLink.name }}</b-dropdown-item>
                  <b-dropdown-item v-if="menuDropLink.linkType === 2" :href="menuDropLink.link" v-on:click="clearPagination">{{ menuDropLink.name }}</b-dropdown-item>
                </div>
                </b-collapse>
                <b-button class="ml-3" v-if="menuLink.linkType === 3" variant="outline-light" @click="logout">{{ menuLink.name }} <font-awesome-icon class="ml-1" :icon="['fal', 'sign-out-alt']" /></b-button>
              </div>
              <b-nav-item v-if="portalConfig.text.externalLinkURL" :href="portalConfig.text.externalLinkURL" target="_blank">{{ portalConfig.text.externalLinkText }}<font-awesome-icon class="ml-1" :icon="['fal', 'external-link']" /></b-nav-item>
            </b-nav>
        </div>
      </Sidebar>
      <BurgerBtn class="d-inline-block d-lg-none"></BurgerBtn>
    </b-navbar>
  </b-container>
    <PNav/>
  </div>
</template>

<script>
import BurgerBtn from '@/components/layout/menu/BurgerBtn'
import Sidebar from '@/components/layout/menu/Sidebar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLink, faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex'
import BetaWarning from '@/components/BetaWarning'
import PNav from '@/components/PNav'

library.add(faExternalLink, faSignOutAlt, faChevronDown)

export default {
  name: 'NavBar',
  components: {
    BurgerBtn,
    Sidebar,
    BetaWarning,
    PNav
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    }),
    isHome () {
      return this.$route.name
    }
  },
  methods: {
    clearPagination () {
      localStorage.removeItem('pageL')
      localStorage.removeItem('pageV')
    },
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
