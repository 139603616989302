<script>

import axios from 'axios'

export default {
  async reportBug (formData) {
    const URL = process.env.VUE_APP_APIURL + 'system-management/report'
    var rAttributes = {
      bugMessage: formData.bugMessage,
      bugSubject: formData.bugSubject,
      bugType: formData.bugType,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      telephone: formData.telephone,
      url: formData.url,
      reportType: formData.reportType
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: rAttributes,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
