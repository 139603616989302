import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import { Auth0Plugin } from './auth'
import VueGtag from 'vue-gtag'
import VGoogleTranslate from 'v-google-translate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { loadProgressBar } from 'axios-progress-bar'

import './assets/scss/styles.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'axios-progress-bar/dist/nprogress.css'
import Vuelidate from 'vuelidate'

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import VueSocialSharing from 'vue-social-sharing'
const googlePreConnnect = document.createElement('link')
googlePreConnnect.rel = 'preconnect'
googlePreConnnect.href = 'https://maps.googleapis.com/maps/api/js'

const awsPreConnnect = document.createElement('link')
awsPreConnnect.rel = 'preconnect'
awsPreConnnect.href = 'https://frogv3.s3.eu-west-2.amazonaws.com'

const typeKitPreConnnect = document.createElement('link')
typeKitPreConnnect.rel = 'preconnect'
typeKitPreConnnect.href = 'https://use.typekit.net'

document.querySelector('head').appendChild(googlePreConnnect)
document.querySelector('head').appendChild(awsPreConnnect)
document.querySelector('head').appendChild(typeKitPreConnnect)

const script = document.createElement('script')
script.async = true
script.defer = true
script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GMAPKEY}`

document.querySelector('head').appendChild(script)

Vue.use(VueSocialSharing)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-text', FontAwesomeLayersText)

Vue.use(
  VueGtag,
  {
    appName: 'FrogV3',
    pageTrackerScreenViewEnabled: true,
    bootstrap: false
  },
  router
)

Vue.use(VGoogleTranslate)

var VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)

// Import environment variables
const domain = process.env.VUE_APP_AUTHDOMAIN
const clientId = process.env.VUE_APP_AUTHCLIENT
const audience = process.env.VUE_APP_AUTHAUDIENCE

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(BootstrapVue, IconsPlugin)
Vue.use(Vuelidate)
Vue.use(loadProgressBar)

Vue.prototype.$portalAlias = window.location.hostname
Vue.prototype.$portalConfig = {}

new Vue({
  beforeCreate: function () {
    Vue.prototype.$portalAlias = window.location.hostname
    Vue.prototype.$portalConfig = {}
  },
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
