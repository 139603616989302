<template>
<div>
  <div class="panic-btn d-none d-md-block" v-if="!isHidden">
    <div class="close-btn">
      <b-button v-on:click="isHidden = true" size="sm" variant="primary"><font-awesome-icon class="icon" :icon="['fas', 'times-circle']" /></b-button>
    </div>
    <b-button size="lg" variant="primary" @click="getAway()"><h5><strong>PRESS “ESC”<br></strong> TO QUICK ESCAPE</h5><p>A new tab will open and this page will redirect to Google.</p></b-button>
  </div>
</div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faTimesCircle)
export default {
  name: 'PanicButton',
  data: () => ({
    isHidden: false
    // portalConfig: {}
  }),
  methods: {
    getAway () {
      sessionStorage.clear()
      window.open('http://www.bbc.co.uk/weather', '_newtab')
      window.location.replace('http://www.google.com/')
    }
  },
  created () {
    const that = this
    document.addEventListener('keyup', function (evt) {
      if (evt.keyCode === 27) {
        that.getAway()
      }
    })
  }
}
</script>
