<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="toggleNav" v-if="getNavStatus"></div>
    <transition name="slide">
      <div v-if="getNavStatus" class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations(['toggleNav', 'resetNav'])
  },
  computed: {
    ...mapGetters(['getNavStatus'])
  },
  mounted () {
    this.resetNav() // ensure loads in docked modde
  }
}
</script>
