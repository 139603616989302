<template>
  <div id="app">
    <component :is='layout || "div"'>
      <!-- Router-Views will display different routes based on name, configured in router/index.js -->
      <router-view />
    </component>
    <CookieBanner v-if="portalConfig.trackingID"/>
  </div>
</template>
<script>
import CookieBanner from '@/components/CookieBanner'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => {
    return {
      loading: true
    }
  },
  components: {
    CookieBanner
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    }),
    layout () {
      return (this.$route.meta.layout)
    },
    trackingID () {
      return this.portalConfig.trackingID
    }
  },
  methods: {
    dynamicFavicon () {
      setTimeout(() => {
        const favicon = document.getElementById('favicon')
        if (this.portalConfig.favicon !== null) {
          favicon.href = this.portalConfig.favicon
        }
      }, 1000)
    }
  },
  mounted () {
    this.dynamicFavicon()
  }
}
</script>
