<template>
  <div>
    <section class="champ-cinema-hero">
      <b-jumbotron class="mb-0">
        <b-container class="h-100">
          <b-row align-v="center" class="h-100">
            <b-col cols="12" lg="8"><h2 class="font-weight-bold text-white banner-text">{{ portalConfig.text.mainTagLine }}</h2></b-col>
            <b-col cols="12" lg="4">
              <div class="tell-story">
                <b-row>
                  <b-col cols="12" lg="6">
                    <p class="text-white text-lg-right mt-2">Tell your story…</p>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-button variant="physical" href="https://frogsystems.co.uk/champions_cinema_tell_your_story/" target="_blank">Click Here</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-jumbotron>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChampCinemaHero',
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  }
}
</script>
