<template>
  <div>
    <b-link class="nav-item" @click="$bvModal.show(`${title}`)">{{ title }}</b-link>
    <b-modal :id="title" :title="title" scrollable size="xl"
    >
    <div v-html="content"></div>
      <template v-slot:modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" aria-label="Got It" style="color: #ffffff;" variant="primary" @click="ok()">
          Got It
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'DataModal',
  props: {
    title: String,
    content: String
  }
}
</script>
