<template>
<div v-if="portalConfig.banner === true">
  <b-alert v-model="showDismissibleAlert" class="alert-banner mb-0" dismissible>
    If you are in need of immediate support you can text '<b>Shout</b>' to <b>85258</b> or ring the <b>Samaritans</b> on <a href="tel:116123">116 123</a>
    <br/>
    If you are worried about the safety of yourself or someone you know, you can reach out to emergency services by calling <a href="tel:999">999</a> or <a href="tel:111">111</a>.
  </b-alert>
</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'BetaWarning',
  data: () => ({
    dismissSecs: 10,
    dismissCountDown: 0,
    showDismissibleAlert: true
  }),
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>
