<template>
  <div class="main-search-area">
    <b-container>
      <b-row>
        <b-col cols="11" md="12" offset="1" offset-md="0">
          <h2 class="frog-top-tag-line mt-2">{{ portalConfig.text.topTagLine }}</h2>
          <h1 class="frog-title-main-tag-line">{{ portalConfig.text.mainTagLine }}</h1>
          <h2 class="frog-bottom-tag-line">{{ portalConfig.text.bottomTagLine }}</h2>

          <b-row>
            <b-col cols="6">
              <div class="frog-bottom-tag-line--logo-frog">
              </div>
            </b-col>
            <b-col cols="6">
              <div class="frog-bottom-tag-line--logo-nhs"></div>
            </b-col>
          </b-row>

           <b-row class="mb-2" v-if="portalConfig.logos.length !== 0">
            <b-col cols="6" md="3" v-for="logo in portalConfig.logos" :key="logo.id" :order="logo.order">
              <b-link :href="logo.linkURL" target="_blank">
                <b-img class="logo-image mb-2" :src="logo.imageUrl" v-b-tooltip.v-primary :title="logo.name" :alt="logo.name"></b-img>
              </b-link>
            </b-col>
           </b-row>

          <div v-if="locationConfig" class="main-search mb-5">
            <b-form ref="form" @submit.stop.prevent="redirectSearch">
              <div>
                <b-row align-h="center">
                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="portalConfig.filterText.mainTag"
                      label-for="form-input"
                      :state="tagState"
                      invalid-feedback="Please enter at least one search term"
                    >
                      <GroupSelect v-if="groupSelectConfig" @termsEmitted="updateTerms" @termRemoved="removeTerm" />
                      <multiselect
                        v-if="!groupSelectConfig"
                        v-model="items"
                        :multiple="true"
                        track-by="value"
                        label="text"
                        placeholder="Start typing..."
                        :options="listingTagOptions"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="portalConfig.filterConfiguration.dropDownClose"
                        :max="portalConfig.filterConfiguration.tagLimit"
                        :hide-selected="true"
                        :max-height="250"
                        selectLabel=""
                        deselectLabel=""
                        :limit="portalConfig.filterConfiguration.tagLimit"
                        :limit-text="limitText"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="portalConfig.filterText.mainLocation"
                      label-for="form-input"
                    >
                      <LocationSearch @locationEmitted="getLocation" @locationRemoved="removeLocation" />
                      <!-- <b-form-input v-model="searchterms.postcode" :formatter="formatter" placeholder="eg. G51 1PZ"></b-form-input> -->
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group
                      :state="distanceState"
                      :label="portalConfig.filterText.mainDistance"
                      label-for="distance-input"
                      invalid-feedback="Distance requires a postcode"
                    >
                      <b-form-select
                        :disabled="!searchterms.postcode"
                        id="distance-input"
                        v-model="searchterms.distance"
                        value-field="item"
                        :options="optionsdistance"
                        :state="distanceState"
                      >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                    <b-button :disabled="!searchterms.postcode && termState !== true" type="submit" class="search-btn search-btn--start" variant="primary">Search</b-button>
                </b-row>
              </div>
            </b-form>
          </div>
          <div v-else class="main-search mb-5">
            <b-form ref="form" @submit.stop.prevent="redirectSearch">
              <div>
                <b-row align-h="center">
                  <b-col cols="12">
                    <b-form-group
                      :label="portalConfig.filterText.mainTag"
                      label-for="form-input"
                      :state="tagState"
                      invalid-feedback="Please enter at least one search term"
                    >
                      <GroupSelect v-if="groupSelectConfig" @termsEmitted="updateTerms" @termRemoved="removeTerm" />
                      <multiselect
                        v-if="!groupSelectConfig"
                        v-model="items"
                        :multiple="true"
                        track-by="value"
                        label="text"
                        placeholder="Type to Search"
                        :options="listingTagOptions"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="portalConfig.filterConfiguration.dropDownClose"
                        :max="portalConfig.filterConfiguration.tagLimit"
                        :hide-selected="true"
                        :max-height="250"
                        selectLabel=""
                        deselectLabel=""
                        :limit="portalConfig.filterConfiguration.tagLimit"
                        :limit-text="limitText"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                    <b-button :disabled="termState !== true" type="submit" class="search-btn search-btn--start" variant="primary">Search</b-button>
                </b-row>
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
      <br>
      <br>
    </b-container>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect'
import SearchService from '@/services/Search.vue'
import LocationSearch from '@/components/LocationSearch'
import GroupSelect from '@/components/GroupMultiSelect'
import { mapGetters } from 'vuex'
export default {
  name: 'MainSearch',
  components: {
    Multiselect,
    LocationSearch,
    GroupSelect
  },
  data: () => ({
    locationConfig: { type: Boolean, default: true },
    groupSelectConfig: { type: Boolean, default: false },
    tagState: null,
    distanceState: null,
    listingTagOptions: [],
    searchText: '', // If value is false, reset searchText & searchItem
    items: [],
    lastSelectItem: {},
    tags: [],
    searchterms: {
      supporttype: 'Organisation',
      topic: null,
      topicName: '',
      distance: null,
      postcode: null,
      gender: null,
      agegroup: null,
      viewpoint: null,
      resourceType: null,
      terms: [],
      tagterms: [],
      referringPage: 'Main'
    },
    optionsdistance: [
      { value: null, text: 'Please Select' }, 10, 20, 30, 40, 50, 100
    ]
  }),
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    }),
    supportTypeState () {
      return Boolean(this.searchterms.supporttype)
    },
    termState () {
      return Boolean((this.items.length > 0 && this.items.length <= 3) || (this.searchterms.terms.length > 0 && this.searchterms.terms.length <= 3))
    }
  },
  // when the instance is created, get all the tags in the instance
  created () {
    this.checkFilterConfig()
    this.getTags()
  },
  methods: {
    limitText (count) {
      return `and ${count} other terms`
    },
    formatter (value) {
      return value.toUpperCase()
    },
    async getTags () {
      if (!this.groupSelectConfig) {
        const endpoint = 'tags'
        await SearchService.getRequest(this.portalConfig.portalName, endpoint)
        // take the returned array
          .then((response) => {
            // loop over the array
            response.forEach((element) => {
              // push the tag id and tag name to the selectoptions array
              this.listingTagOptions.push({ value: element.id, text: element.tagName })
            })
          })
          .catch(function (error) {
            // eslint-disable-next-line
            console.error(error)
          })
      }
    },
    getLocation (location) {
      if (location) {
        this.searchterms.postcode = location.name
      }
    },
    removeLocation () {
      if (this.searchterms.postcode) {
        this.searchterms.postcode = ''
      }
    },
    checkFilterConfig () {
      const filterConfig = this.portalConfig.filterConfiguration
      if (filterConfig !== null) {
        if (filterConfig.location !== null) {
          this.locationConfig = filterConfig.location
        }
        if (filterConfig.groupedTags !== null) {
          this.groupSelectConfig = filterConfig.groupedTags
        }
      }
    },
    checkFormValidity () {
      let valid = false
      if (this.locationConfig) {
        if (this.searchterms.postcode !== null && this.searchterms.postcode !== '') {
          this.distanceState = true
          valid = true
        } else {
          this.distanceState = false
          if (this.searchterms.terms.length === 0) {
            if (this.items.length !== 0) {
              this.tagState = true
              valid = true
            }
          } else {
            valid = true
          }
        }
        return valid
      } else {
        if (this.searchterms.terms.length === 0) {
          if (this.items.length !== 0) {
            this.tagState = true
          } else {
            this.tagState = false
          }
        } else {
          this.tagState = true
        }
        if (this.tagState) {
          valid = true
        }
        return valid
      }
    },
    redirectSearch () {
      if (this.checkFormValidity()) {
        if (this.locationConfig) {
          if (this.items.length !== 0) {
            this.searchterms.terms = []
            this.searchterms.tagterms = []
            // take the tagID from the selected tags and pass to array
            this.items.forEach((element) => {
              this.searchterms.terms.push(element.value)
              this.searchterms.tagterms.push(element.text)
            })
            localStorage.setItem('searchTermsObject', JSON.stringify(this.searchterms))
            this.$router.push({ path: '/search' })
          } else {
            localStorage.setItem('searchTermsObject', JSON.stringify(this.searchterms))
            this.$router.push({ path: '/search' })
          }
        } else {
          if (this.searchterms.terms.length !== 0) {
            localStorage.setItem('searchTermsObject', JSON.stringify(this.searchterms))
            this.$router.push({ path: '/search' })
          } else if (this.searchterms.terms.length === 0 && this.items.length !== 0) {
            this.searchterms.terms = []
            this.searchterms.tagterms = []
            // take the tagID from the selected tags and pass to array
            this.items.forEach((element) => {
              this.searchterms.terms.push(element.value)
              this.searchterms.tagterms.push(element.text)
            })
            localStorage.setItem('searchTermsObject', JSON.stringify(this.searchterms))
            this.$router.push({ path: '/search' })
          }
        }
      }
    },
    updateTerms (values) {
      this.searchterms.terms = []
      this.searchterms.tagterms = []
      // take the tagID from the selected tags and pass to array
      values.forEach((element) => {
        this.searchterms.terms.push(element.id)
        this.searchterms.tagterms.push(element.tagName)
      })
    },
    removeTerm (value) {
      const tagIndex = this.searchterms.tagterms.findIndex(i => i === value.tagName)
      if (tagIndex !== -1) {
        this.searchterms.tagterms.splice(tagIndex, 1)
      }
      const termIndex = this.searchterms.terms.findIndex(i => i === value.id)
      if (termIndex !== -1) {
        this.searchterms.terms.splice(termIndex, 1)
      }
    }
  }
}

</script>
