<template>
  <div>
  <multiselect v-model="selectedLocation" label="name" track-by="value" placeholder="town/postcode" :options="locations" :multiple="true" :searchable="searchStatus" :allow-empty="true" selectLabel="" deselectLabel="" :loading="isLoading" :internal-search="false" :close-on-select="true" open-direction="bottom" :options-limit="300" :limit="1" :limit-text="limitText" :max-height="600" :show-no-options="false" :show-no-results="false" :hide-selected="true" @search-change=getLocations @select="dispatchAction" @remove="emitRemove">
  </multiselect>
</div>
</template>

<script>

import { debounce } from 'debounce'
import { Multiselect } from 'vue-multiselect'
import GetLocations from '@/services/GetLocations'

export default {
  name: 'LocationSearch',
  components: {
    Multiselect
  },
  props: ['clearLocation'],
  data: () => ({
    selectedLocation: [],
    locations: [],
    isLoading: false
  }),
  computed: {
    searchStatus: function () {
      let status
      if (this.selectedLocation.length >= 1) {
        status = false
      } else {
        status = true
      }
      return status
    }
  },
  watch: {
    clearLocation (clearLocation) {
      if (this.clearLocation === true) {
        this.selectedLocation = []
      }
    }
  },
  methods: {
    limitText (count) {
      return `and ${count} other locations`
    },
    getLocations: debounce(async function (query) {
      this.isLoading = true
      await GetLocations.getLocations(query)
        .then((res) => {
          if (res.status === 200) {
            this.locations = res.data
            this.isLoading = false
          } else {
            // eslint-disable-next-line
            console.error(res.data)
          }
        })
    }, 300),
    dispatchAction (actionName) {
      if (actionName) {
        this.$emit('locationEmitted', actionName)
      }
    },
    emitRemove () {
      this.$emit('locationRemoved')
    },
    clearAll () {
      this.selectedLocation = []
    }
  }
}
</script>
