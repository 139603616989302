<template>
  <div>
    <div class="frog-footer" v-if="portalConfig.footerType === 1">
      <b-container>
        <div class="footer-nav">
          <b-row>
            <b-col cols="12" md="5" xl="6">
              <div class="nav">
                <b-link
                  target="_blank"
                  class="nav-item d-none-frog"
                  v-if="portalConfig.insightsButton === true"
                  href="https://ashia.info"
                  >Insights</b-link
                >
                <DataModal
                  v-for="modals in footerModals"
                  :key="modals.name"
                  :title="modals.name"
                  :content="modals.detail"
                />
                <b-link
                  class="nav-item"
                  v-for="link in externalLinks"
                  :key="link.name"
                  :href="link.detail"
                  :title="link.name"
                  target="_blank"
                  >{{ link.name }}</b-link
                >
                <b-link
                  class="nav-item"
                  v-if="portalConfig.applyButton === true"
                  v-b-modal.modal-prevent-closing
                  >Apply to be listed</b-link
                >
              </div>
            </b-col>
            <b-col cols="12" md="6" xl="4">
              <p
                class="footer-nav__address"
                v-for="address in footerAddress"
                :key="address.name"
              >
                {{ address.detail }}
              </p>
            </b-col>
            <b-col cols="12" md="1" xl="2" class="text-lg-center">
              <div class="frog-social">
                <b-link
                  v-for="links in socialLinks"
                  :key="links.name"
                  :href="links.detail"
                  :title="links.name"
                  target="_blank"
                  ><font-awesome-icon :icon="['fab', links.name]"
                /></b-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <PanicButton v-if="portalConfig.panicButton === true" />
      <ReportModal v-if="portalConfig.contactForm !== null" />
      <PhoneModal
        v-for="modals in this.portalConfig.floatingElements"
        :key="modals.name"
        :buttonTitle="modals.buttonTitle"
        :modalTitle="modals.modalTitle"
        :type="modals.type"
        :content="modals.detail"
      />
    </div>

    <div
      class="frog-footer-champ-cinema mt-4"
      v-if="portalConfig.footerType === 2"
    >
      <b-container>
        <div class="footer-nav">
          <b-row>
            <b-col cols="12" md="3" xl="4">
              <b-navbar-brand
                href="https://frogsystems.co.uk/"
                target="_blank"
              ></b-navbar-brand>
            </b-col>
            <b-col cols="12" md="6" xl="6">
              <div class="nav">
                <b-link
                  target="_blank"
                  class="nav-item d-none-frog"
                  v-if="portalConfig.insightsButton === true"
                  href="https://ashia.info"
                  >Insights</b-link
                >
                <DataModal
                  v-for="modals in footerModals"
                  :key="modals.name"
                  :title="modals.name"
                  :content="modals.detail"
                />
                <b-link
                  class="nav-item"
                  v-for="link in externalLinks"
                  :key="link.name"
                  :href="link.detail"
                  :title="link.name"
                  target="_blank"
                  >{{ link.name }}</b-link
                >
                <b-link
                  class="nav-item"
                  v-if="portalConfig.applyButton === true"
                  v-b-modal.modal-prevent-closing
                  >Apply to be listed</b-link
                >
              </div>
            </b-col>
            <b-col cols="12" md="3" xl="2" class="text-lg-center">
              <div class="frog-social">
                <b-link
                  v-for="links in socialLinks"
                  :key="links.name"
                  :href="links.detail"
                  :title="links.name"
                  target="_blank"
                  ><font-awesome-icon :icon="['fab', links.name]"
                /></b-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <PanicButton v-if="portalConfig.panicButton === true" />
      <ReportModal v-if="portalConfig.contactForm !== null" />
      <PhoneModal
        v-for="modals in this.portalConfig.floatingElements"
        :key="modals.name"
        :buttonTitle="modals.buttonTitle"
        :modalTitle="modals.modalTitle"
        :type="modals.type"
        :content="modals.detail"
      />
    </div>
    <div class="footer-help-musucuans" v-if="portalConfig.footerType === 3">
      <div class="helpline">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div
                class="footer-nav__address text-center"
                v-for="address in footerAddress"
                :key="address.name"
              >
                <div v-html="address.detail"></div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-row class="justify-content-center align-items-center">
          <b-col cols="12" md="8">
            <div class="footer-nav mt-2">
              <div class="nav">
                <b-link
                  class="nav-item"
                  v-for="link in externalLinks"
                  :key="link.name"
                  :href="link.detail"
                  :title="link.name"
                  target="_blank"
                  >{{ link.name }}</b-link
                >
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="4" align-self="center">
            <div class="text-center">
              powered by
              <b-link href="https://frogsystems.co.uk/">
                <b-img
                  width="60"
                  src="https://frogv3.s3.eu-west-2.amazonaws.com/website/helpmusicians/images/FS_Indigo_Stacked.svg"
                  title="frog systems"
                ></b-img>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <PanicButton v-if="portalConfig.panicButton === true" />
      <PhoneModal
        v-for="modals in this.portalConfig.floatingElements"
        :key="modals.name"
        :buttonTitle="modals.buttonTitle"
        :modalTitle="modals.modalTitle"
        :type="modals.type"
        :content="modals.detail"
      />
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons'
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faTiktok
} from '@fortawesome/free-brands-svg-icons'
import DataModal from '@/components/Modals/DataModal'
import PhoneModal from '@/components/Modals/PhoneModal'
import ReportModal from '@/components/Modals/ReportModal'
import PanicButton from '@/components/PanicButton'
import { mapGetters } from 'vuex'

library.add(
  faMapMarkerAlt,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faTiktok
)
export default {
  name: 'Footer',
  components: {
    DataModal,
    PhoneModal,
    ReportModal,
    PanicButton
  },
  component: {
    faMapMarkerAlt,
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faTiktok
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    }),
    footerModals: function () {
      return this.portalConfig.footer.filter(function (m) {
        return m.type === 1
      })
    },
    footerAddress: function () {
      return this.portalConfig.footer.filter(function (a) {
        return a.type === 2
      })
    },
    socialLinks: function () {
      return this.portalConfig.footer.filter(function (s) {
        return s.type === 3
      })
    },
    externalLinks: function () {
      return this.portalConfig.footer.filter(function (e) {
        return e.type === 4
      })
    }
  }
}
</script>
