import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Default from '@/components/layout/DefaultTheme'
import DirectoryTheme from '@/components/layout/DirectoryTheme'
import HomeTheme from '@/components/layout/HomeTheme'
import VueMeta from 'vue-meta'
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)
Vue.use(VueMeta)

const fourOFour = () => import('../components/404.vue')
const forbidden = () => import('../components/403.vue')
const error = () => import('../components/Error.vue')
const home = () => import('../views/Home.vue')
const searchResults = () => import('../views/SearchResults.vue')
const elasticSearch = () => import('../views/ElasticSearch.vue')
const searchQuery = () => import('../views/SearchQuery.vue')
const searchCategory = () => import('../views/SearchCategory.vue')
const championsCinema = () => import('../views/ChampionsCinema.vue')
const wellness = () => import('../views/Wellness.vue')
const smallChanges = () => import('../views/SmallChanges.vue')
const smallChangesSearch = () => import('../views/SmallChangesSearch.vue')
const smallChangesDetail = () => import('../views/SmallChangesDetail.vue')
const wellnessDirectory = () => import('../views/WellnessDirectory.vue')
const videoDetail = () => import('../views/VideoDetail.vue')
const ashiaAdvantage = () => import('../views/AshiaAdvantage.vue')
const resourceDirectory = () => import('../views/ResourceDirectory.vue')
const listingDetail = () => import('../views/ListingDetail.vue')
const raisingTheGame = () => import('../views/RaisingTheGame.vue')

const routes = [
  {
    path: '*',
    name: '404',
    component: fourOFour,
    meta: {
      layout: Default
    }
  },
  {
    path: '/forbidden',
    name: '403',
    component: forbidden,
    meta: {
      layout: Default
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: error,
    meta: {
      layout: Default
    }
  },
  {
    path: '/',
    name: 'Home',
    component: home,
    beforeEnter: authGuard,
    meta: {
      layout: HomeTheme
    }
  },
  {
    path: '/search',
    name: 'Search Results',
    component: searchResults,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/results',
    name: 'Results',
    component: elasticSearch,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/search/:cat/:tag',
    name: 'Search Query',
    props: true,
    component: searchQuery,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/search/:cat',
    name: 'Search Category',
    props: true,
    component: searchCategory,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/ChampionsCinema',
    name: 'Champions Cinema',
    component: championsCinema,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/Wellness',
    name: 'Wellness',
    component: wellness,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/smallchanges',
    name: 'smallchanges',
    component: smallChanges,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/smallchanges/:courseID',
    props: true,
    name: 'smallChangesSearch',
    component: smallChangesSearch,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/smallchanges/:courseID/:weekID',
    props: true,
    name: 'smallChangesWeekDetails',
    component: smallChangesDetail,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/WellnessDirectory',
    name: 'Wellness Directory',
    component: wellnessDirectory,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/Advantage',
    name: 'Ashia Advantage',
    component: ashiaAdvantage,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  // remove broadcast module from router until master is ready to catch up
  // {
  //   path: '/broadcast',
  //   name: 'Broadcast',
  //   component: () => import(/* webpackChunkName: "Champions Cinema" */ '../views/Broadcast.vue'),
  //   beforeEnter: authGuard,
  //   meta: {
  //     layout: DirectoryTheme
  //   }
  // },
  {
    path: '/video/:id',
    name: 'VideoDetail',
    props: true,
    component: videoDetail,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/SupportDirectory',
    name: 'Support Directory',
    component: () =>
      import(
        /* webpackChunkName: "Support Directory" */ '../views/SupportDirectory.vue'
      ),
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/ResourceDirectory',
    name: 'Resource Directory',
    component: resourceDirectory,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/listing/:id/:vanityurl',
    name: 'Listing Detail',
    props: true,
    component: listingDetail,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/match-day-toolkit',
    name: 'Rasing The Game',
    props: true,
    component: raisingTheGame,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  },
  {
    path: '/match-day-toolkit/:childpage',
    name: 'Rasing The Game Details',
    props: true,
    component: raisingTheGame,
    beforeEnter: authGuard,
    meta: {
      layout: DirectoryTheme
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history'
})

export default router
