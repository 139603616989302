<template>
  <div>
    <multiselect
      v-model="value"
      :options="childTags"
      :multiple="true"
      group-values="children"
      group-label="parentName"
      :group-select="true"
      placeholder="Type to search"
      track-by="id"
      label="tagName"
      :searchable="true"
      :allow-empty="true"
      :close-on-select="portalConfig.filterConfiguration.dropDownClose"
      :max="portalConfig.filterConfiguration.tagLimit"
      :hide-selected="true"
      :max-height="250"
      selectLabel=""
      deselectLabel=""
      :groupSelect="false"
      selectGroupLabel=""
      deselectGroupLabel=""
      @input="emitValues"
      @remove="removeValue"
      :limit="portalConfig.filterConfiguration.tagLimit"
      :limit-text="limitText"
    >
      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import SearchService from '@/services/Search.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      filterConfiguration: {},
      optionstopic: [],
      allTags: [],
      tagOptions: [],
      childTags: [],
      value: []
    }
  },
  created () {
    this.initFilter()
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  },
  methods: {
    async initFilter () {
      this.filterConfiguration = this.portalConfig.filterConfiguration
      if (this.filterConfiguration.advanced) {
        await SearchService.advancedFilter(this.portalConfig.portalName)
          .then((res) => {
            if (res.status === 200) {
              res.data.supportTypes.forEach(element => {
                this.optionstopic.push({ value: element.id, text: element.tagName })
              })
              res.data.allTags.forEach(element => {
                this.allTags.push({ value: element.id, text: element.tagName })
                this.tagOptions.push({ value: element.id, text: element.tagName })
              })
              res.data.childTags.forEach(element => {
                this.childTags.push(element)
              })
              this.childTags.forEach((element) => {
                const index = this.optionstopic.findIndex(i => i.value === element.parent)
                if (index !== -1) {
                  element.parentName = this.optionstopic[index].text
                }
              })
            }
          })
      }
    },
    limitText (count) {
      return `and ${count} other terms`
    },
    emitValues (values) {
      this.$emit('termsEmitted', values)
    },
    removeValue (value) {
      this.$emit('termRemoved', value)
    }
  }
}
</script>
