<template>
  <div v-if="portalConfig.themeLayout !== undefined">
    <div v-if="portalConfig.themeLayout.id === 1" class="home-theme">
      <SectionHero/>
      <slot />
      <Footer />
    </div>
    <div v-if="portalConfig.themeLayout.id === 2" class="champ-theme">
      <NavBarChamp />
      <ChampCinemaHero />
      <slot />
      <Footer />
    </div>
    <div v-if="portalConfig.themeLayout.id === 3" class="help-musicians-theme">
      <b-container v-if="portalConfig.navigationType === 1">
        <NavBar />
      </b-container>
      <NavBarChamp v-if="portalConfig.navigationType === 2"/>
      <NavBarStirling v-if="portalConfig.navigationType === 3"/>
      <HeroWithSearch />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
import SectionHero from '@/components/SectionHero'
import NavBar from '@/components/layout/menu/NavBar'
import NavBarStirling from '@/components/layout/menu/NavBarStirling'
import NavBarChamp from '@/components/layout/menu/NavBarChamp'
import ChampCinemaHero from '@/components/ChampCinemaHero'
import HeroWithSearch from '@/components/HeroWithSearch'
import Footer from '@/components/layout/Footer'
import { mapGetters } from 'vuex'
export default {
  name: 'HomeTheme',
  components: {
    NavBar,
    NavBarStirling,
    NavBarChamp,
    SectionHero,
    ChampCinemaHero,
    HeroWithSearch,
    Footer
  },
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    })
  }
}
</script>
