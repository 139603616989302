<template>
  <div class="directory-theme">
    <NavBar />
    <slot />
  </div>
</template>

<script>
import NavBar from '@/components/layout/menu/NavBarDefault'
export default {
  name: 'DirectoryTheme',
  components: {
    NavBar
  }
}
</script>
