<template>
  <div>
    <BetaWarning />
    <div class="nav-bar-champ mb-4">
      <b-container>
        <b-navbar>
          <b-navbar-brand v-b-tooltip.bottomright.v-primary :title="portalConfig.text.logoToolTip" v-on:click="clearPaginationSolo" :to="'/'"></b-navbar-brand>
          <Sidebar class="d-block">
            <div class="sidebar-panel-nav">
                <b-nav vertical>
                  <div><b-nav-item @click.once.stop="handleNavClick" :to="'/'">Home</b-nav-item></div>
                  <div v-for="(menuLink, index) in portalConfig.menuItems" :key="index">
                    <b-nav-item v-if="menuLink.linkType === 1 && menuLink.name !== 'Learning from Life'" :to="menuLink.link" @click.once.stop="handleNavClick">{{ menuLink.name }}</b-nav-item>
                    <b-nav-item v-if="menuLink.linkType === 2" :href="menuLink.link" @click.once.stop="handleNavClick">{{ menuLink.name }}</b-nav-item>
                    <b-nav-item v-if="menuLink.linkType === 4" v-b-toggle="menuLink.link" class="m-1">{{ menuLink.name }} <span class="float-right"><font-awesome-icon :icon="['fas', 'chevron-down']" /></span></b-nav-item>
                    <b-nav-item v-if="menuLink.name === 'Learning from Life'" :to="'/Results'" @click.once.stop="search(menuLink.name); handleNavClick();">{{ menuLink.name }}</b-nav-item>
                    <b-collapse v-if="menuLink.linkType === 4" :id="menuLink.link">
                    <div v-for="(menuDropLink, index) in menuLink.children" :key="index">
                      <b-dropdown-item v-if="menuDropLink.linkType === 1" @click.once.stop="search(menuDropLink.link); handleNavClick();">{{ menuDropLink.name }}</b-dropdown-item>
                      <!-- <b-dropdown-item v-if="menuDropLink.linkType === 2" href="#" @click="search(menuDropLink.link) + clearPagination">{{ menuDropLink.name }}</b-dropdown-item> -->
                    </div>
                    </b-collapse>
                    <b-button class="ml-3" v-if="menuLink.linkType === 3" variant="outline-light" @click="logout">{{ menuLink.name }} <font-awesome-icon class="ml-1" :icon="['fal', 'sign-out-alt']" /></b-button>
                  </div>
                  <b-nav-item v-if="portalConfig.text.externalLinkURL" :href="portalConfig.text.externalLinkURL" target="_blank">{{ portalConfig.text.externalLinkText }}<font-awesome-icon class="ml-1" :icon="['fal', 'external-link']" /></b-nav-item>
                </b-nav>
            </div>
          </Sidebar>
          <ElasticSearch class="d-inline-block" />
          <v-google-translate v-if="portalConfig.language" class="g-translate mx-2 mx-lg-4"
            :defaultLanguageCode="defaultLanguageCode"
          />
          <BurgerBtn class="d-inline-block"></BurgerBtn>
        </b-navbar>
      </b-container>
    </div>
    <PNav />
  </div>
</template>

<script>
import ElasticSearch from '@/components/ElasticSearch'
import BurgerBtn from '@/components/layout/menu/BurgerBtn'
import PNav from '@/components/PNav'
import Sidebar from '@/components/layout/menu/Sidebar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLink, faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BetaWarning from '@/components/BetaWarning'

library.add(faExternalLink, faSignOutAlt, faChevronDown)

export default {
  name: 'NavBar',
  components: {
    ElasticSearch,
    BurgerBtn,
    Sidebar,
    PNav,
    BetaWarning
  },
  data: () => ({
    defaultLanguageCode: 'en'
  }),
  computed: {
    ...mapGetters({
      portalConfig: 'getPortalConfig'
    }),
    isHome () {
      return this.$route.name
    }
  },
  methods: {
    ...mapMutations({ toggle: 'toggleNav' }),
    handleNavClick () {
      this.toggle()
      this.clearPagination()
    },
    clearPagination () {
      localStorage.removeItem('pageL')
      localStorage.removeItem('pageV')
    },
    clearPaginationSolo () {
      localStorage.removeItem('pageL')
      localStorage.removeItem('pageV')
    },
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },
    ...mapActions(['getSearchResults']),
    search (query) {
      if (this.portalConfig.themeLayout.id === 2 || this.portalConfig.themeLayout.id === 3) {
        if (query.length !== 0) {
          const searchQuery = {
            portalName: this.portalConfig.portalName,
            query: query
          }
          this.getSearchResults(searchQuery)
          if (this.$route.path !== '/results') {
            this.$router.push('/results')
          }
        }
      }
    }
  }
}
</script>
