<script>

import axios from 'axios'

export default {
  async getLocations (query) {
    const url = process.env.VUE_APP_APIURL + 'articles/listings/search/locations/auto-complete'
    var locationData = {
      location: query
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: locationData,
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
