import { getInstance } from './index'
import axios from 'axios'
import { store } from '../store/store'

export const authGuard = (to, from, next) => {
  const authService = getInstance()
  const fn = async () => {
    const config = store.getters.getPortalConfig
    if (Object.keys(config).length === 0) {
      if (authService.$portalAlias) {
        if (authService.$portalAlias === 'localhost') {
          authService.$portalAlias = 'showracism'
        }
        const URL =
          process.env.VUE_APP_APIURL +
          'system-management/portal/' +
          authService.$portalAlias
        const ops = {
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: URL
        }
        axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
        try {
          await axios(ops).then(async (res) => {
            if (res.status === 200) {
              // if portal is public, continue
              if (res.data.status === false) {
                const URL =
                  process.env.VUE_APP_APIURL +
                  'system-management/portal/' +
                  authService.$portalAlias +
                  '/config'
                const ops = {
                  method: 'GET',
                  headers: { 'content-type': 'application/json' },
                  url: URL
                }
                axios.defaults.headers.common['x-api-key'] =
                  process.env.VUE_APP_APIKEY
                try {
                  // get portal config
                  await axios(ops).then(async (res) => {
                    if (res.status === 200) {
                      if (res.data) {
                        // set portal information
                        // localStorage.setItem('portalConfig', JSON.stringify(res.data))
                        const payload = {
                          portalConfig: res.data
                        }
                        store.dispatch('commitConfig', payload)
                        document.documentElement.setAttribute(
                          'theme',
                          res.data.theme.toLowerCase()
                        )
                        return next()
                      }
                    } else if (res.status === 403) {
                      next({ path: '/forbidden' })
                    } else {
                      next({ path: '/error' })
                    }
                  })
                } catch (error) {
                  // eslint-disable-next-line
                  console.error(error.response)
                  next({ path: '/error' })
                }
              }
              // if portal is private, continue with login
              if (res.data.status === true) {
                let connectionName = ''
                if (
                  res.data.connectionName !== null ||
                  res.data.connectionName !== undefined
                ) {
                  connectionName = res.data.connectionName
                } else if (
                  res.data.connectionName === null ||
                  res.data.connectionName === undefined
                ) {
                  next({ path: '/error' })
                }
                // If the user is authenticated, continue with the route
                if (authService.isAuthenticated) {
                  const token = await authService.getTokenSilently()
                  const URL =
                    process.env.VUE_APP_APIURL +
                    'system-management/portal/' +
                    authService.$portalAlias +
                    '/config'
                  const ops = {
                    method: 'GET',
                    headers: { 'content-type': 'application/json' },
                    url: URL
                  }
                  axios.defaults.headers.common['x-api-key'] =
                    process.env.VUE_APP_APIKEY
                  axios.defaults.headers.common.Authorization = `Bearer ${token}`
                  try {
                    await axios(ops).then(async (res) => {
                      if (res.status === 200) {
                        if (res.data) {
                          // set portal information
                          const payload = {
                            portalConfig: res.data
                          }
                          store.dispatch('commitConfig', payload)
                          document.documentElement.setAttribute(
                            'theme',
                            res.data.theme.toLowerCase()
                          )
                          return next()
                        }
                      } else if (res.status === 403) {
                        next({ path: '/forbidden' })
                      } else {
                        next({ path: '/error' })
                      }
                    })
                  } catch (error) {
                    if (error.response.status === 403) {
                      next({ path: '/forbidden' })
                    } else {
                      next({ path: '/error' })
                    }
                  }
                } else {
                  // Otherwise, log in with connection identity if available
                  // authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
                  authService.loginWithRedirect({
                    appState: { targetUrl: to.fullPath },
                    connection: connectionName
                  })
                }
              }
            } else {
              next({ path: '/error' })
            }
          })
        } catch (error) {
          // eslint-disable-next-line
          console.error(error.response)
          next({ path: '/error' })
        }
      }
    } else {
      return next()
    }
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn()
    }
  })
}
