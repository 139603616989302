import axios from 'axios'

const state = () => ({
  results: [],
  searchTerm: ''
})

const getters = {
  searchResult: (state) => {
    return state.results
  },
  searchTerm: (state) => {
    return state.searchTerm
  }
}

const actions = {
  async getSearchResults ({ commit }, query) {
    commit('resetResults')
    commit('setSearchTerm', query.query)
    sessionStorage.setItem('query', query.query)
    const URL = process.env.VUE_APP_APIURL + 'articles/' + query.portalName + '/search/elastic?' + query.count
    const body = {
      term: query.query
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: body,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      if (res.status === 200) {
        commit('returnResults', res.data)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  },
  async getPaginatedResults ({ commit }, query) {
    commit('resetResults')
    commit('setSearchTerm', query.query)
    sessionStorage.setItem('query', JSON.stringify(query.query))
    const URL = process.env.VUE_APP_APIURL + 'articles/' + query.portalName + '/search/elastic?' + query.count + query.pageType
    const body = {
      term: query.query
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: body,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    try {
      const res = await axios(ops)
      if (res.status === 200) {
        commit('returnResults', res.data)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }
}

const mutations = {
  returnResults: (state, results) => {
    state.results = results
  },
  setSearchTerm: (state, query) => {
    state.searchTerm = query
  },
  resetResults: (state) => {
    state.results = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
